import React from "react"
import LayoutCourse from "../../../layouts/course"
import Seo from "../../../components/seo"
import Heading from "../../../components/heading"
import Stack from "../../../components/stack"
import Box from "../../../components/box"
import CourseFooterNext from "../../../components/course-footer-next"
import Paragraph from "../../../components/paragraph"
import Bullet from "../../../components/bullet"
import BulletList from "../../../components/bullet-list"
import Note from "../../../components/note"
import TextLink from "../../../components/textlink"
import { getCourseNavigation } from "../../../store/courses"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "jahreszeiten" })

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext
          label="Kurs starten"
          to="/kurse/jahreszeiten/01-jahreszeitengedicht"
        />
      }
      navigation={navigation}
    >
      <Seo title="Hölderlin und die Jahreszeiten" />
      <Box>
        <Stack>
          <Heading as="h2" level={2}>
            Informationen für Lehrer*innen
          </Heading>
          <Paragraph>
            In diesem Kurs lernen die Schüler*innen ein Jahreszeiten-Gedicht von
            Friedrich Hölderlin kennen (
            <TextLink to="/kurse/jahreszeiten/01-jahreszeitengedicht">
              Kapitel 1
            </TextLink>
            ) und entwickeln anschließend einen Schreibplan für ein eigenes
            Jahreszeitengedicht (
            <TextLink to="/kurse/jahreszeiten/02-eigenes-gedicht">
              Kapitel 2
            </TextLink>
            ). In Kapitel 1 nähern sich die Schüler*innen Hölderlins Gedicht
            zunächst über seine äußere Form. Sie erfahren, wie wichtig diese
            Form für Hölderlins Arbeit an seinen Gedichten war und wie sich
            diese im Vortrag auf den Klang und Rhythmus des Gedichts
            niederschlägt. Darauf erfolgt eine detailliertere Texterschließung
            anhand der verwendeten Nomen, Adjektive und Verben. Im nächsten
            Schritt setzen sich die Schüler*innen mit der überlieferten
            Gedichthandschrift auseinander und entdecken, welches Wissen in
            solchen literarischen Zeugnissen steckt. Schließlich werden sie für
            sprecherische Interpretationsmöglichkeiten des Gedichts
            sensibilisiert und entwickeln einen eigenen gestaltenden
            Gedichtvortrag. In Kapitel 2 geht es daran, ein eigenes
            Jahreszeiten-Gedicht zu verfassen. Hierfür erhalten die
            Schüler*innen Anregungen zu Themen und Bildern des Gedichts, seiner
            äußeren Ordnung und klanglichen Gestaltung.
          </Paragraph>
          <Note title="Hinweis">
            Der Kurs kann sowohl von den Schüler*innen eigenständig bearbeitet
            als auch in den Unterricht integriert werden. Alle Einheiten sind
            interaktiv angelegt. Sie fördern eine produktive und kreative
            Erschließung literarischer Formen und Texte, sensibilisieren die
            Schüler*innen für die Machart literarischer Texte und tragen zur
            ästhetischen Bildung bei. Die Kapitel 1 und 2 können aufeinander
            aufbauend, aber auch unabhängig voneinander behandelt werden.
          </Note>
          <Paragraph>
            Die Kapitel sind an folgenden Inhalten des Bildungsplans angelehnt:
          </Paragraph>
          <BulletList size={[3, 3, 4, 5]}>
            <Bullet>
              Methoden der Texterschließung: Syntax, Morphologie, Semantik
            </Bullet>
            <Bullet>
              Auseinandersetzung mit Zeugnissen literarischer Überlieferung
            </Bullet>
            <Bullet>
              Literarische Texte verstehen und in ihrer ästhetischen Qualität
              erfassen
            </Bullet>
            <Bullet>Texte sinngebend und gestaltend vorlesen</Bullet>
            <Bullet>Texte planen und formulieren</Bullet>
            <Bullet>Medien gestalten</Bullet>
          </BulletList>
          <Paragraph>
            Die von den Schüler*innen eigenständig erarbeiteten Inhalte können
            in Form eines Unterrichtsgesprächs noch einmal aufgegriffen und
            abgefragt werden. Mögliche Impulse wären beispielsweise:
          </Paragraph>
          <BulletList size={[3, 3, 4, 5]}>
            <Bullet>
              Wo lebte Hölderlin, als er seine Jahreszeitengedichte verfasste?
            </Bullet>
            <Bullet>
              Warum hat er so oft über die Jahreszeiten geschrieben?
            </Bullet>
            <Bullet>
              Was ist euch beim Zählen der Silben des Gedichts aufgefallen?
            </Bullet>
            <Bullet>
              Welche Wortart hat Hölderlin im Gedicht am häufigsten verwendet?
            </Bullet>
            <Bullet>
              Mit welchem Namen hat Hölderlin das Gedicht im Manuskript
              unterschrieben?
            </Bullet>
            <Bullet>Möchte jemand das Gedicht vortragen?</Bullet>
            <Bullet>
              Möchte jemand sein selbst verfasstes Gedicht vorstellen?
            </Bullet>
          </BulletList>
          <Paragraph>
            Die selbst verfassten Gedichte können auch an einer
            Jahreszeiten-Wäscheleine im Klassenzimmer aufgehängt werden.
          </Paragraph>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
